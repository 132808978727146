export default function ({ $axios, app, error: nuxtError }) {
  let router = app.router
  let store = app.store
  let showMaintenanceToast = function (message) {
    if (!app.$loader.forceVisible) {
      app.$loader.forceVisible = true
      app.$loader.show()
      app.$toast.info(I18n.t(message || "currently_undergoing_maintenance"), {
        position: "bottom-center",
        action: [
          {
            text: I18n.t("reload"),
            onClick: () => router.go()
          },
          {
            text: I18n.t("close"),
            onClick: (e, toastObject) => {
              app.$loader.forceVisible = false
              app.$loader.hide()
              toastObject.goAway(0)
            }
          },
        ]
      })
    }
  }

  $axios.setHeader('Accept', 'application/vnd.edatanow+json; version=0');
  $axios.setHeader('Content-Type', 'multipart/form-data');

  $axios.onRequest(config => {
    store.commit('xhrRequests/addRequest', config.url)
    if (store.getters['xhrRequests/requestsUnderway']) app.$loader.show()
    if (!config.data) config.data = {}
    config.data.utf8 = "✓";
    config.headers["Cache-Control"] = "no-cache"
    return config;
  })

  $axios.onResponse(response => {
    if (store.getters["login/isLoggedIn"]) {
      store.dispatch("login/resetTimer")
    }

    store.commit('xhrRequests/completeRequest', response.config.url)
    if (store.getters['xhrRequests/allRequestsComplete']) app.$loader.hide()
    if (response.data.message) app.$toast.success(response.data.message, { duration: 5000 });
    if (response.data && response.data.redirect_path) {
      let savedPath = window.localStorage.getItem("savedPath");
      if (savedPath) {
        window.localStorage.removeItem("savedPath")
        router.push(savedPath)
      } else {
        router.push(response.data.redirect_path);
      }
    } else if (response.data && response.data.refresh_page) {
      router.go()
    }

    return response;
  })

  $axios.onError(error => {
    let response = error.response

    if (response) {
      store.commit('xhrRequests/completeRequest', response.config.url)

      switch (response.status) {
        case 404:
          nuxtError({ statusCode: 404, message: '404' })
          break
        case 403:
          if (store.state.login.user) store.commit('login/setShowLogin', true);
          break;
        case 401:
          router.push("/");
          app.$toast.error(I18n.t("unauthorized"), { duration: 10000 })
          break;
        case 503:
          showMaintenanceToast()
          return Promise.resolve(response)
        case 504:
          showMaintenanceToast("timeout_toast")
          break;
        default:
          break;
      }

      let data = response.data

      if (data.redirect_path) {
        router.push(data.redirect_path);
      } else if (data && data.refresh_page) {
        router.go()
      }

      if (data.message) app.$toast.error(data.message, { duration: 10000 })
      if (data.errors) data.errors.forEach(error => app.$toast.error(error, { duration: 10000 }))
    }

    app.$loader.hide()

    return Promise.reject(error);
  })
}