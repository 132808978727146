import axios from 'axios';
import UrlManipulator from './UrlManipulator'

let header = {
  headers: {
    accept: "application/vnd.edatanow+json; version=0",
    'Content-Type': 'multipart/form-data'
  }
}

let defaultSuccess = false;
let axiosService = axios;

export default {
  getNextPathNameElement(current) {
    return UrlManipulator.getNextPathNameElement(current);
  },
  flattenObject(object) {
    return UrlManipulator.flattenObject(object);
  },

  flattenAndbuildPathWithSearchParams(object) {
    return UrlManipulator.flattenAndbuildPathWithSearchParams(object);
  },

  buildPathWithSearchParams(object) {
    return UrlManipulator.buildPathWithSearchParams(object);
  },
  getSearchParams() {
    return UrlManipulator.getSearchParams();
  },
  buildSearchUrl(object) {
    return UrlManipulator.buildSearchUrl(object);
  },
  UrlParams(object) {
    return UrlManipulator.UrlParams(object);
  },

  flattenObjectWithArrayOfObjects(object) {
    return UrlManipulator.flattenObjectWithArrayOfObjects(object);
  },

  buildSearchPathWithSanitized(params) {
    return UrlManipulator.buildSearchPathWithSanitized(params);
  },
  //reasonable defaults
  init(nuxtAxios) {
    if (nuxtAxios) {
      axiosService = nuxtAxios
    } else {
      this.defaultSuccess = response => {
        if (response.data && response.data.redirect_path) {
          window.location.href = response.data.redirect_path;
        }

        return response;
      };

      axiosService.interceptors.response.use(this.defaultSuccess, error => {
        if (error.response) {
          if (error.response.status === 403) window.location.replace("/login");
          if (error.response.status === 401) window.location.replace("/");
        }
        return Promise.reject(error);
      });
    }

    return this
  },
  redirectSuccess(response) {
    if (response.data && response.data.redirect_path) {
      window.location.href = response.data.redirect_path;
    }
  },
  log(obj, message = "") {
    console.log(`${message} ${JSON.stringify(obj, null, 2)}`);
  },

  copyAndDeleteNullProperties(param) {
    let cleanCopy = { ...param };

    Object.keys(cleanCopy).forEach(
      key => cleanCopy[key] == null && delete cleanCopy[key]
    );
    return cleanCopy;
  },

  copyAndDeleteEmptyStringProperties(param) {
    let cleanCopy = { ...param };

    Object.keys(cleanCopy).forEach(
      key => cleanCopy[key] === "" && delete cleanCopy[key]
    );
    return cleanCopy;
  },

  postOrPatch(url, data) {
    let id = data[Object.keys(data)[0]].id;
    if (id) {
      return this.patch(`${url}/${id}`, data);
    } else {
      return this.post(url, data);
    }
  },
  patch(url, data) {
    return axiosService.patch(
      url,
      this.objectToFormData(data),
      header
    );
  },
  put(url, data) {
    return axiosService.put(
      url,
      this.objectToFormData(data),
      header
    );
  },
  post(url, data) {
    return axiosService.post(
      url,
      this.objectToFormData(data),
      header
    );
  },
  delete(url, data) {
    this.defaultSuccess = null;

    let fullData = { ...data, ...header };

    return axiosService.delete(url, fullData);
  },
  get(url, data) {
    this.defaultSuccess = null;

    let fullData = { ...data, ...header };
    return axiosService.get(url, fullData);
  },
  objectToFormData(obj, form, namespace) {
    let fd = form || new FormData();
    let formKey;

    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (namespace) {
          formKey = namespace + "[" + property + "]";
        } else {
          formKey = property;
        }

        // if the property is an object, but not a File,
        // use recursivity.
        if (
          typeof obj[property] === "object" &&
          !(obj[property] instanceof File)
        ) {
          if (
            Array.isArray(obj[property]) &&
            typeof obj[property][0] !== "object"
          ) {
            if (obj[property].length > 0) {
              obj[property].forEach(value => {
                fd.append(`${formKey}[]`, value);
              });
            } else {
              fd.append(`${formKey}[]`, []);
            }
          } else if (obj[property] === null) {
            fd.append(formKey, "");
          } else {
            this.objectToFormData(obj[property], fd, formKey);
          }
        } else {
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
      }
    }

    return fd;
  }
};

