import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import iChartHelper from '~/components/common/stores/ichartHelper.js'
import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import { translate } from '~/assets/javascript/localization/Localization';
import Moment from 'moment';

import { getField, updateField } from 'vuex-map-fields';
import gtmEvents from '../assets/javascript/gtmEvents';

const getDefaultState = () => ({
  isJobContext: false,
  searchInProgress: false,
  iChartObject: {},
  shippingIchartObject: {},
  partIchartObject: {},
  csvDownloadPath: "",
  excelDownloadPath: "",
  secondGraphLabel: "",
  shippingGraphLabel: translate('suspect_shipping_labels'),
  partGraphLabel: translate('suspect_part_labels'),
  shortMonthAndDate: Moment().format("MMM DD"),
  checkboxParams: {
    show_top: false,
    show_ppm: false,
    show_prior_year: true
  },
  selectedType: {
    name: translate('default'),
    id: 0
  },
  typeOptions: [
    { name: translate('default'), id: 0 },
    { name: translate('downtime'), id: 1 },
    { name: translate('barcode_verification'), id: 2 }
  ],
  searchUrl: '',
  hasSearched: false
})

export const state = getDefaultState

export const getters = {
  getField,
  iChartObjectPresent(state){
    return state.iChartObject !== null && Object.keys(state.iChartObject).length !== 0;
  },
  partIchartObjectPresent(state){
    return state.partIchartObject !== null && Object.keys(state.partIchartObject).length !== 0;
  },
  shippingIchartObjectPresent(state){
    return state.shippingIchartObject !== null && Object.keys(state.shippingIchartObject).length !== 0;
  },
  searchUrl(state) {
    let deviceId = AxiosWrapper.getSearchParams().device
    let jobId = AxiosWrapper.getNextPathNameElement("jobs")

    if (!deviceId) {
      if (state.selectedType.id === 0){
        return `/data_api/ichart`
      } else if (state.selectedType.id === 1){
        return `/data_api/downtime_ichart`
      } else {
        return `/data_api/barcode_verification_ichart`
      }
    } else {
      if (state.selectedType.id === 0) {
        return `/data_api/dashboard/devices/jobs/${jobId}/ichart`
      } else if (state.selectedType.id === 1) {
        return `/data_api/dashboard/devices/jobs/${jobId}/downtime_ichart`
      } else {
        return `/data_api/dashboard/devices/jobs/${jobId}/barcode_verification_ichart`
      }
    }
  }
}

export const mutations = {
  updateField,
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setSelectedType(state, selectedType) {
    state.selectedType = {name: selectedType.name, id: selectedType.id}
  },
  initCheckboxParams(state, params={}) {
    Object.assign(state.checkboxParams, getDefaultState().checkboxParams)
    if (params.show_top) state.checkboxParams.show_top = (params.show_top == 'true')
    if (params.show_ppm) state.checkboxParams.show_ppm = (params.show_ppm == 'true')
    if (params.show_prior_year) state.checkboxParams.show_prior_year = (params.show_prior_year == 'true')
  },
  setData(state, data) {
    let params = AxiosWrapper.getSearchParams();

    if (state.selectedType.id === 0){
      if (data.ichart_object !== undefined){
        state.iChartObject = data.ichart_object;
        state.csvDownloadPath = `/data_api/download_csv/ichart${AxiosWrapper.buildSearchUrl(params)}&export_type=csv&type=Default`
        state.excelDownloadPath = `/data_api/download_csv/ichart${AxiosWrapper.buildSearchUrl(params)}&export_type=axlsx&type=Default`
        state.secondGraphLabel = null
        state.shippingIchartObject = {}
        state.partIchartObject = {}
      }
    }else if (state.selectedType.id === 1){
      if (data.ichart_object !== undefined){
        state.iChartObject = data.ichart_object;
        state.csvDownloadPath = `/data_api/download_csv/ichart${AxiosWrapper.buildSearchUrl(params)}&export_type=csv`
        state.excelDownloadPath = `/data_api/download_csv/ichart${AxiosWrapper.buildSearchUrl(params)}&export_type=axlsx`
        state.secondGraphLabel = translate('top_reasons_for_period')
        state.shippingIchartObject = {}
        state.partIchartObject = {}
      }
    }else{
      if (data.shipping_ichart_object !== undefined && data.part_ichart_object !== undefined){
        state.iChartObject = {}
        state.csvDownloadPath = null
        state.excelDownloadPath = null
        state.shippingIchartObject = data.shipping_ichart_object
        state.partIchartObject = data.part_ichart_object
      }
    }
  }
}

export const actions = {
  async init({ dispatch, commit, rootGetters }) {
    commit("resetState")

    let params = {}
    let urlParams = AxiosWrapper.getSearchParams();
    let paramsMap = iChartHelper.defaultParamsMap();

    let jobId = AxiosWrapper.getNextPathNameElement("jobs")
    let jobRevisionId = AxiosWrapper.getNextPathNameElement("job_revisions")

    if (jobId || jobRevisionId) {
      let selectedParams = {
        job_id: ((jobId != "") ? jobId : null),
        job_revision_id: ((jobRevisionId != "") ? jobRevisionId : null),
        start_date: Moment().subtract(1, 'months').format('YYYY-MM-DD')
      }

      commit("updateField", { path: 'isJobContext', value: true })

      params = { ...selectedParams, ...urlParams }
    } else {
      params = urlParams
    }

    dispatch("selects/initForReportPage", { params, paramsMap }, { root: true });
    dispatch("initCheckboxParams", params);
  },
  async initCheckboxParams({ commit }, params) {
    if (Object.keys(params).length > 0) {
      commit("initCheckboxParams", params)
    }
  },
  async search({ dispatch, rootGetters }, context) {
    gtmEvents.reportGenerated({
      context,
      type: "ICharts"
    })

    let paramsForSearch = {
      ...ConfigHelper.mapParams(rootGetters["selects/paramsWithCustomValues"], iChartHelper.defaultRevertParamsMap()),
      start_date: rootGetters['datetime/getStartDate'],
    }

    let paramsForUrl = {...paramsForSearch, ...rootGetters["selects/prefixedCustomValues"]}

    history.replaceState(history.state, 'iChartSearch', AxiosWrapper.buildSearchPathWithSanitized(paramsForUrl));
    dispatch("getCallForSearch", {params: paramsForSearch})
  },
  async getCallForSearch({ dispatch, getters, commit }, {params, optionalUrl}) {
    let searchUrl = getters.searchUrl

    if (optionalUrl) {
      searchUrl = optionalUrl
    }

    this.$axios.get(searchUrl, { params }).then(({data})=>{
      commit('updateField', {path: 'hasSearched', value: true})
      dispatch("setResponseData", data);
      dispatch("contentWithHeader/update", null, {root: true})
    })
  },
  async setResponseData({commit}, data) {
    commit("setData", data)
  },
}