const middleware = {}

middleware['pageRedirections'] = require('../middleware/pageRedirections.js');
middleware['pageRedirections'] = middleware['pageRedirections'].default || middleware['pageRedirections']

middleware['redirectToHttps'] = require('../middleware/redirectToHttps.js');
middleware['redirectToHttps'] = middleware['redirectToHttps'].default || middleware['redirectToHttps']

middleware['resetHeaders'] = require('../middleware/resetHeaders.js');
middleware['resetHeaders'] = middleware['resetHeaders'].default || middleware['resetHeaders']

middleware['resetSelects'] = require('../middleware/resetSelects.js');
middleware['resetSelects'] = middleware['resetSelects'].default || middleware['resetSelects']

middleware['scrollToTop'] = require('../middleware/scrollToTop.js');
middleware['scrollToTop'] = middleware['scrollToTop'].default || middleware['scrollToTop']

middleware['sessionHandler'] = require('../middleware/sessionHandler.js');
middleware['sessionHandler'] = middleware['sessionHandler'].default || middleware['sessionHandler']

export default middleware
