import Vue from "vue";
import AxiosWrapper from "~/assets/javascript/AxiosWrapper"
import ModalHelper from "~/components/ModalHelper"
import { updateField, getField } from 'vuex-map-fields'
import { translate } from '~/assets/javascript/localization/Localization'
import { formatGroups } from '~/components/QuestionnaireGroups/helper.js'

const getDefaultState = () => ({
  showMoveQuestionnaires: false,
  showCopyQuestionnaires: false,
  showAssignJobs: false,
  selectedQuestionnaireGroupId: null,
  questionnaireGroupName: null,
  questionnaireGroup: null,
  questionnaireGroups: null,
  questionnaires_for_checked_list: [],
  selectedQuestionnaires: [],
  jobInProgress: "In Progress",
  jobFailed: "Failed",
  questionnaireGroupsObject: {},
  defaultGroupsObject: {},
  jobOptions: [],
  selectedJobs: [],
  indexQuestionnaireGroups: [],
  indexDefaultGroups: [],
  status: 'active',
  questionnaireGroupActive: true,
  currentView: 'none',
  dataGrouped: false
})

export const state = getDefaultState

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setShowMoveQuestionnaires(state, value) {
    state.showMoveQuestionnaires = value
  },
  setShowCopyQuestionnaires(state, value) {
    state.showCopyQuestionnaires = value
  },
  setShowAssignJobs(state, value) {
    state.showAssignJobs = value
  },
  setMoveQuestionnaireInitData(state, data) {
    state.questionnaireGroupName = data.questionnaire_group_name;
    state.questionnaireGroups = data.questionnaire_group_options
    state.questionnaires_for_checked_list = data.questionnaires_for_checked_list
  },
  setCopyQuestionnairesInitData(state, data) {
    state.questionnaireGroup = data.questionnaire_group;
    state.questionnaireGroupsObject = data.questionnaire_groups_questionnaires[0]
    state.defaultGroupsObject = data.default_groups_questionnaires
  },
  setAssignJobsInitData(state, data){
    state.questionnaireGroup = data.questionnaire_group
    state.jobOptions = data.job_options
    state.selectedJobs = data.group_selected_jobs
  },
  setCollapsibleAndExpandedKeys(state){
    state.jobOptions.forEach(option => {
      option['collapsible'] = true
      option['expanded'] = true
    })
  },
  setNameKeyForQuestionnaires(state) {
    state.questionnaireGroupsObject["questionnaire_groups"].forEach(qg => qg["questionnaires"].map(q => q["name"] = q["current_name"]))
  },
  setGroupsInitData(state, data) {
    state.indexQuestionnaireGroups = formatGroups(data['questionnaire_groups_for_index']['data'])
    state.indexQuestionnaireGroups = state.indexQuestionnaireGroups.map(group => group.attributes)

    state.indexDefaultGroups =  formatGroups(data['default_groups_for_index']['data'])
    state.indexDefaultGroups = state.indexDefaultGroups.map(group => group.attributes)
  },
  questionnaireToggled(state, {value, questionnaire}) {
    Vue.set(questionnaire, 'selected', value)
  },
  questionnaireToggledForCopy(state, questionnaire) {
    Vue.set(questionnaire, 'selected', !questionnaire.selected)
  },
  updateName(state, {newName, questionnaire}) {
    Vue.set(questionnaire, 'name', newName)
  },
  selectAllQuestionnaires(state, {value, questionnaires}) {
    questionnaires.forEach(q => Vue.set(q, 'selected', value));
  },
  deleteGroup(state, {groups, id, index}) {
    if (state.dataGrouped) {
      state.indexQuestionnaireGroups.forEach(grouped => {
        let index = grouped.data.findIndex(group => group.id === id)
        if (index > -1) {
          grouped.data.splice(index, 1)
        }
      })

      state.indexDefaultGroups.forEach(grouped => {
        let index = grouped.data.findIndex(group => group.id === id)
        if (index > -1) {
          grouped.data.splice(index, 1)
        }
      })
    } else {
      groups.splice(index, 1)
    }

  },
  updateField
}

export const getters = {
  getField,
  enableMoveButton(state) {
    return state.selectedQuestionnaireGroupId != null && state.selectedQuestionnaires.length > 0
  },
  enableCopyButton(state) {
    if (Object.keys(state.questionnaireGroupsObject).includes('questionnaire_groups')) return state.questionnaireGroupsObject['questionnaire_groups'].some(qg => qg['questionnaires'].some(q => q.selected))
  },
  editQuestionnaireGroupUrl: (state) => (id) =>  {
    return `/audit/questionnaire_groups/${id}`
  },
  questionnairesUrl: (state) => (id) =>  {
    return `/audit/questionnaire_groups/${id}/questionnaires`
  },
  copyQuestionnairesUrl: (state) => (id) =>  {
    return `/audit/questionnaire_groups/${id}/copy_questionnaires`
  },
  assignJobsUrl: (state) => (id) =>  {
    return `/audit/questionnaire_groups/${id}/assign_jobs`
  },
  questionnaireGroupsForCopy(state) {
    return state.questionnaireGroupsObject.questionnaire_groups
  },
  lesseeName(state) {
    return state.questionnaireGroupsObject.lessee_name
  }
}

export const actions = {
  async openMoveQuestionnaires({ commit }, groupId) {
    AxiosWrapper.get(`/data_api/audit/questionnaire_groups/${groupId}/get_data_for_move_questionnaire_to_questionnaire_group`).then(({ data }) => {
      commit('setMoveQuestionnaireInitData', data)
      ModalHelper.addShowParamToUrl('show_move_questionnaires', groupId, 'Move Questionnaires')
      commit("setShowMoveQuestionnaires", true)
    })
  },
  async closeMoveQuestionnaires({ commit }) {
    ModalHelper.removeShowParamFromUrl('show_move_questionnaires', 'Questionnaire Groups')
    commit("setShowMoveQuestionnaires", false)
  },
  async updateSelectedQuestionnaires({ commit }, val) {
    commit('updateField', { path: 'selectedQuestionnaires', value: val})
  },
  async moveButtonPressed({ state }, router) {
    const moveQuestionnairesUrl = '/data_api/audit/questionnaire_groups/apply_to_new_group'
    ModalHelper.removeShowParamFromUrl('show_move_questionnaires', 'Questionnaire Groups')
    AxiosWrapper.put(moveQuestionnairesUrl, { new_questionnaire_group_id: state.selectedQuestionnaireGroupId, questionnaire_ids: state.selectedQuestionnaires }).then(router.go()).catch(this.defaultError)
  },
  async openCopyQuestionnaires({ commit }, groupId) {
    AxiosWrapper.get(`/data_api/audit/questionnaire_groups/${groupId}/get_data_for_copy_questionnaire_to_questionnaire_group`).then(({ data }) => {
      commit('setCopyQuestionnairesInitData', data)
      commit('setNameKeyForQuestionnaires')
      ModalHelper.addShowParamToUrl('show_copy_questionnaires', groupId, 'Copy Questionnaires')
      commit("setShowCopyQuestionnaires", true)
    })
  },
  async closeCopyQuestionnaires({ commit }) {
    ModalHelper.removeShowParamFromUrl('show_copy_questionnaires', 'Questionnaire Groups')
    commit("setShowCopyQuestionnaires", false)
  },
  async copyButtonPressed({ state }, router) {
    const copyQuestionnairesUrl = `/data_api/audit/questionnaire_groups/${state.questionnaireGroup.id}/clone_questionnaires`
    let requestObject = ModalHelper.questionnairesObjectForCopyRequest(state.questionnaireGroupsObject, state.defaultGroupsObject)

    let audit_questionnaire_group = requestObject["audit_questionnaire_group"]
    audit_questionnaire_group["questionnaire_ids"] = requestObject["questionnaire_ids"]

    this.$axios.patch(copyQuestionnairesUrl, { audit_questionnaire_group }).then(() => {
      ModalHelper.removeShowParamFromUrl('show_copy_questionnaires', 'Questionnaire Groups')
      router.go()
    }).catch(this.defaultError)
  },
  async openAssignJobs({ commit }, groupId) {
    AxiosWrapper.get(`/data_api/audit/questionnaire_groups/${groupId}/get_data_for_assign_jobs_to_questionnaire_group`).then(({ data }) => {
      commit('setAssignJobsInitData', data)
      commit('setCollapsibleAndExpandedKeys')
      ModalHelper.addShowParamToUrl('show_assign_jobs', groupId, 'Assign Jobs')
      commit("setShowAssignJobs", true)
    })
  },
  async updateSelectedJobs({ commit }, val) {
    commit('updateField', { path: 'selectedJobs', value: val})
  },
  async closeAssignJobs({ commit }) {
    ModalHelper.removeShowParamFromUrl('show_assign_jobs', 'Questionnaire Groups')
    commit("setShowAssignJobs", false)
  },
  async saveAssignedJobs({ state }, router){
    ModalHelper.removeShowParamFromUrl('show_assign_jobs', 'Questionnaire Groups')
    AxiosWrapper.post(`/data_api/audit/questionnaire_groups/${state.questionnaireGroup.id}`, { assign_jobs: true, audit_questionnaire_group: { job_ids: state.selectedJobs} }).then(router.go())
  },
  async initGroupsIndex({ dispatch, commit, state }) {
    let params = {
      status: state.status,
      group_by: state.currentView
    }

    AxiosWrapper.get('/data_api/audit/questionnaire_groups/get_data_for_questionnaires_groups_index', { params }).then(({ data }) => {
      if (state.currentView === 'job') {
        commit('updateField', { path: 'dataGrouped', value: true })
      } else {
        commit('updateField', { path: 'dataGrouped', value: false })
      }

      commit('setGroupsInitData', data)
      dispatch('showComponentIfPresentInUrl')
    })
  },
  async showComponentIfPresentInUrl({ dispatch }) {
    let params = AxiosWrapper.getSearchParams()
    if (Object.keys(params).includes('show_move_questionnaires')) dispatch('openMoveQuestionnaires', params['show_move_questionnaires'])
    if (Object.keys(params).includes('show_copy_questionnaires')) dispatch('openCopyQuestionnaires', params['show_copy_questionnaires'])
    if (Object.keys(params).includes('show_assign_jobs')) dispatch('openAssignJobs', params['show_assign_jobs'])
  },
  async deleteGroup({ commit }, {groups, id, index}) {
    if (confirm(translate("are_you_sure"))) {
      AxiosWrapper.delete(`/data_api/audit/questionnaire_groups/${id}`).then(commit('deleteGroup',{groups, id, index})).catch(this.defaultError)
    }
  },
  async radioFilterChanged({commit, state, dispatch}) {
    commit('updateField', { path: 'questionnaireGroupActive', value: !state.questionnaireGroupActive})

    dispatch('initGroupsIndex')
  },
  changeView({commit, dispatch}, view) {
    commit('updateField', { path: 'currentView', value: view })

    commit('updateField', { path: 'indexDefaultGroups', value: [] })
    commit('updateField', { path: 'indexQuestionnaireGroups', value: [] })

    dispatch('initGroupsIndex')
  }
}
